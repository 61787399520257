import { tv } from "../../../util/tv";

export const langSwitcher = tv(
  {
    slots: {
      base: ["max-w-[200px]"],
      select: [
        "w-full",
        "block",
        "rounded-lg",
        "select-none",
        "text-display-16 font-medium",
        "py-2 pl-4 pr-8",
        "outline-none appearance-none",
        "bg-gray-600 text-gray-40",
        "border-2 border-gray-600 focus:border-blue-400",
        "bg-select-arrow bg-no-repeat bg-[right_12px_center] bg-[length:10px_6px]",
      ],
    },
  },
  {
    twMerge: false,
  }
);
