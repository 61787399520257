import { useParams } from "next/navigation";
import type { LayoutSideMenuGroup } from "../../../types";
import { layoutGridVariant } from "./layout-grid.variants";

export interface UseLayoutGridProps {
  data: {
    sideMenu?: LayoutSideMenuGroup[];
  };
  children?: React.ReactNode;
}

export const useLayoutGrid = (props: UseLayoutGridProps) => {
  const { children, data } = props;
  const { sideMenu = [] } = data;

  const params = useParams();

  const [page, category] = Array.isArray(params.slug) ? params.slug : [];
  const isDocPage = page === "docs";

  const styles = layoutGridVariant({ isDocPage });

  const sections = sideMenu.find((group) => group.slug === category)?.menu;

  return {
    styles,
    isDocPage,
    sections: sections ?? [],
    data,
    children,
  };
};
