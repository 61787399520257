"use client";

import {
  createContext,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";
import { createStore, useStore, type StoreApi } from "zustand";
import { remToPx } from "../../../util/layout";
import type { LayoutSideMenuItem } from "../../../types";

export interface Section extends LayoutSideMenuItem {
  offsetRem?: number;
  tag?: string;
  headingRef?: React.RefObject<HTMLHeadingElement>;
}

export interface SectionState {
  sections: Section[];
  visibleSections: string[];
  setVisibleSections: (visibleSections: string[]) => void;
  registerHeading: ({
    slug,
    ref,
    offsetRem,
  }: {
    slug: string;
    ref: React.RefObject<HTMLHeadingElement>;
    offsetRem: number;
  }) => void;
}

interface SectionProviderProps {
  sections: Section[];
  children: React.ReactNode;
}

function createSectionStore(sections: Section[]) {
  return createStore<SectionState>()((set) => ({
    sections,
    visibleSections: [],
    setVisibleSections: (visibleSections) => {
      set((state) =>
        state.visibleSections.join() === visibleSections.join()
          ? {}
          : { visibleSections }
      );
    },
    registerHeading: ({ slug, ref, offsetRem }) => {
      set((state) => {
        return {
          sections: state.sections.map((section) => {
            if (section.slug === slug) {
              return {
                ...section,
                headingRef: ref,
                offsetRem,
              };
            }
            return section;
          }),
        };
      });
    },
  }));
}

function useVisibleSections(sectionStore: StoreApi<SectionState>) {
  const setVisibleSections = useStore(
    sectionStore,
    (s) => s.setVisibleSections
  );
  const sections = useStore(sectionStore, (s) => s.sections);

  useEffect(() => {
    function checkVisibleSections() {
      const { innerHeight, scrollY } = window;
      const newVisibleSections: string[] = [];

      for (
        let sectionIndex = 0;
        sectionIndex < sections.length;
        sectionIndex++
      ) {
        // @ts-ignore
        const { slug, headingRef, offsetRem = 0 } = sections[sectionIndex];

        if (!headingRef?.current) {
          continue;
        }

        const offset = remToPx(offsetRem);
        const top = headingRef.current.getBoundingClientRect().top + scrollY;

        if (sectionIndex === 0 && top - offset > scrollY) {
          newVisibleSections.push("_top");
        }

        const nextSection = sections[sectionIndex + 1];
        const bottom =
          // @ts-ignore
          (nextSection.headingRef?.current?.getBoundingClientRect().top ??
            Infinity) +
          scrollY -
          // @ts-ignore
          remToPx(nextSection.offsetRem ?? 0);

        if (
          (top > scrollY && top < scrollY + innerHeight) ||
          (bottom > scrollY && bottom < scrollY + innerHeight) ||
          (top <= scrollY && bottom >= scrollY + innerHeight)
        ) {
          newVisibleSections.push(slug);
        }
      }

      setVisibleSections(newVisibleSections);
    }

    const raf = window.requestAnimationFrame(() => {
      checkVisibleSections();
    });

    window.addEventListener("scroll", checkVisibleSections, { passive: true });
    window.addEventListener("resize", checkVisibleSections);

    return () => {
      window.cancelAnimationFrame(raf);
      window.removeEventListener("scroll", checkVisibleSections);
      window.removeEventListener("resize", checkVisibleSections);
    };
  }, [setVisibleSections, sections]);
}

export function useSectionStore<T>(selector: (state: SectionState) => T) {
  const store = useContext(SectionStoreContext);
  return useStore(store!, selector);
}

const SectionStoreContext = createContext<StoreApi<SectionState> | null>(null);

const useIsomorphicLayoutEffect =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

function SectionProvider({ sections, children }: SectionProviderProps) {
  const [sectionStore] = useState(() => createSectionStore(sections));

  useVisibleSections(sectionStore);

  useIsomorphicLayoutEffect(() => {
    sectionStore.setState({ sections });
  }, [sectionStore, sections]);

  return (
    <SectionStoreContext.Provider value={sectionStore}>
      {children}
    </SectionStoreContext.Provider>
  );
}

export { SectionStoreContext, SectionProvider };
