import { tv, type VariantProps } from "../../../util/tv";

export const cookieBannerVariant = tv({
  slots: {
    base: [
      "fixed bottom-4 inset-x-0 z-50",
      "max-w-7xl mx-auto",
      "px-6 lg:px-8",
    ],
    banner: [
      "flex flex-col md:flex-row gap-4",
      "items-center justify-between flex-wrap",
      "rounded-xl",
      "p-4 md:p-3",
      "bg-gray-600",
      "shadow-lg",
    ],
    body: ["flex-1 flex items-center", "md:pl-1"],
    policy: ["flex flex-col xs:flex-row gap-2", "w-full md:w-auto"],
  },
});

export type CookieBannerVariantProps = VariantProps<typeof cookieBannerVariant>;

export type CookieBannerReturnVariantProps = ReturnType<
  typeof cookieBannerVariant
>;
