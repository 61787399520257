"use client";

import {
  createContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";

export interface HeaderStateProps {
  share: "hidden" | "visible" | "visible_with_menu";
  blur: boolean;
  hidden: boolean;
  opened: boolean;
  expanded: number | false;
  mobile: boolean;
}

interface HeaderContextProps {
  state: HeaderStateProps;
  setState: Dispatch<SetStateAction<HeaderStateProps>>;
}

interface HeaderProviderProps {
  children: React.ReactNode;
}

const initialHeaderState: HeaderStateProps = {
  share: "hidden",
  blur: false,
  hidden: false,
  opened: false,
  expanded: false,
  mobile: false,
};

const HeaderContext = createContext<HeaderContextProps>({
  state: initialHeaderState,
  setState: () => {},
});

function HeaderProvider({ children }: HeaderProviderProps) {
  const [state, setState] = useState(initialHeaderState);

  return (
    <HeaderContext.Provider value={{ state, setState }}>
      {children}
    </HeaderContext.Provider>
  );
}

export { HeaderContext, HeaderProvider };
