/* eslint-disable react-hooks/exhaustive-deps -- disable */
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { usePathname } from "next-intl/client";
import { useMotionValueEvent, useScroll } from "motion/react";
import { useWindowSize } from "react-use";
import type { Theme } from "../../../types";
import { getContentTypeResourceByPattern } from "../../../util/strapi";
import type { HeaderStateProps } from "./header-context";
import { HeaderContext } from "./header-context";

const MIN_WIDTH_FOR_DESKTOP_MENU = 1024;
const MIN_SCROLL_DOWN_FOR_SHOW_HARE_BAR = 10;
const MIN_SCROLL_UP_FOR_HIDE_SHARE_BAR = 400;
const LIGHT_THEME_WHEN = ["/research/"];
const SHOW_SHARE_BAR_WHEN = ["blog"];

const useHeaderState = () => {
  const { state, setState } = useContext(HeaderContext);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { width } = useWindowSize();
  const { scrollY, scrollYProgress } = useScroll();
  const pathname = usePathname();

  const { opened, expanded, mobile } = state;
  const theme: Theme = LIGHT_THEME_WHEN.includes(pathname) ? "light" : "dark";

  const dispatch = (payload: Partial<HeaderStateProps>) => {
    setState((oldState) => ({ ...oldState, ...payload }));
  };

  const isDesktopAndOpenedMenu = useMemo(
    () => width >= MIN_WIDTH_FOR_DESKTOP_MENU && opened,
    [width, opened]
  );

  const toggleHeader = useCallback(
    (current: number) => {
      const prev = scrollY.getPrevious() || 0;

      const scrollDown = prev < current;
      const isScrollDownEnough = current >= MIN_SCROLL_DOWN_FOR_SHOW_HARE_BAR;
      const isScrollUpEnough = current <= MIN_SCROLL_UP_FOR_HIDE_SHARE_BAR;

      const newHeaderState: Partial<HeaderStateProps> = {};

      if (scrollDown && isScrollDownEnough) {
        newHeaderState.hidden = true;
        newHeaderState.share = "visible";
      } else {
        newHeaderState.hidden = false;
        newHeaderState.share = "visible_with_menu";
      }

      newHeaderState.blur = isScrollDownEnough;

      if (isScrollUpEnough) {
        newHeaderState.share = "hidden";
      }

      dispatch(newHeaderState);
    },
    [scrollY]
  );

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (opened) return;
    toggleHeader(latest);
  });

  useEffect(() => {
    if (width < MIN_WIDTH_FOR_DESKTOP_MENU) {
      !mobile && dispatch({ mobile: true });
    } else {
      mobile && dispatch({ mobile: false });
    }
  }, [width]);

  useEffect(() => {
    isDesktopAndOpenedMenu && dispatch({ opened: false });
  }, [isDesktopAndOpenedMenu]);

  useEffect(() => {
    dispatch({ opened: false, expanded: false });
  }, [pathname]);

  const onToggleMenu = useCallback(
    (active: boolean) => {
      dispatch({ opened: active });
    },
    [opened]
  );

  const closeMenu = useCallback(() => {
    dispatch({ opened: false });
  }, [opened]);

  const openDropdown = (id: number) => {
    if (width < MIN_WIDTH_FOR_DESKTOP_MENU) return;

    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }

    if (expanded !== id) {
      dispatch({ expanded: id });
    }
  };

  const closeDropdown = () => {
    if (width < MIN_WIDTH_FOR_DESKTOP_MENU) return;

    closeTimeoutRef.current = setTimeout(() => {
      dispatch({ expanded: false });
    }, 500);
  };

  const cancelCloseDropdown = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  };

  const { resource } = useMemo(
    () => getContentTypeResourceByPattern(pathname),
    [pathname]
  );

  const showShareBar = useMemo(
    () => SHOW_SHARE_BAR_WHEN.includes(resource),
    [resource]
  );

  return {
    ...state,
    theme,
    showShareBar,
    onToggleMenu,
    closeMenu,
    openDropdown,
    closeDropdown,
    cancelCloseDropdown,
    scrollYProgress,
  };
};

export default useHeaderState;
