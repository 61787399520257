/* eslint-disable react-hooks/exhaustive-deps -- disable */
import { useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import { useWindowSize } from "react-use";
import type { ComponentProps, LayoutSideMenuGroup } from "../../../types";
import useDisableScroll from "../../../hooks/use-disable-scroll";
import {
  sideMenuVariant,
  type SideMenuVariantProps,
} from "./side-menu.variants";

export type UseSideMenuProps = ComponentProps<
  LayoutSideMenuGroup[],
  SideMenuVariantProps
>;

const MIN_WIDTH_FOR_DESKTOP_SIDE_MENU = 1024;

export const useSideMenu = (props: UseSideMenuProps) => {
  const { className = "", theme = "dark", data } = props;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const pathname = usePathname();
  const { width } = useWindowSize();

  const styles = sideMenuVariant({ theme, isOpen });

  useDisableScroll(isOpen, ref);

  useEffect(() => {
    isOpen && setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    const isDesktopAndOpenedSideMenu =
      width >= MIN_WIDTH_FOR_DESKTOP_SIDE_MENU && isOpen;
    isDesktopAndOpenedSideMenu && setIsOpen(false);
  }, [isOpen, width]);

  return {
    ref,
    theme,
    className,
    styles,
    isOpen,
    setIsOpen,
    data,
  };
};
