export const i18n = {
  defaultLocale: "en",
  locales: ["en"],
} as const;

export const locales = {
  en: {
    lang: "en",
    label: "English",
  },
};
