"use client";

import { SideMenu } from "../../common/side-menu";
import { SectionProvider } from "./section-context";
import { useLayoutGrid, type UseLayoutGridProps } from "./use-layout-grid";

export type LayoutGridProps = UseLayoutGridProps;

function LayoutGrid(props: LayoutGridProps) {
  const { styles, isDocPage, sections, data, children } = useLayoutGrid(props);
  const { sideMenu } = data;

  return (
    <SectionProvider sections={sections}>
      <div className={styles.base()}>
        {isDocPage && !!sideMenu ? (
          <div className={styles.sidebar()}>
            <SideMenu data={sideMenu} />
          </div>
        ) : null}
        <div className={styles.content()}>{children}</div>
      </div>
    </SectionProvider>
  );
}

export default LayoutGrid;
