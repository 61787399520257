"use client";

import { Button } from "../../ui/button";
import { Text } from "../../ui/text";
import {
  useCookieBanner,
  type UseCookieBannerProps,
} from "./use-cookie-banner";

export type CookieBannerProps = UseCookieBannerProps;

function CookieBanner(props: CookieBannerProps) {
  const { className, styles, onAccept, show, data } = useCookieBanner(props);

  if (!show) {
    return null;
  }

  const { description, policy, confirm } = data;

  return (
    <div className={styles.base({ className })}>
      <div className={styles.banner()}>
        <div className={styles.body()}>
          <Text size="tinyBody" weight="semibold">
            {description}
          </Text>
        </div>
        <div className={styles.policy()}>
          <Button
            appearance="outline"
            color="white"
            fluid="mobile"
            href={policy.href}
            sectionName={`floating_banner_${policy.label}`}
            size="sm"
            title={policy.label}
          >
            {policy.label}
          </Button>
          <Button
            appearance="solid"
            color="white"
            fluid="mobile"
            onClick={onAccept}
            size="sm"
            type="button"
          >
            {confirm}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CookieBanner;
