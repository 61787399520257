import type { LayoutNavItem } from "../../../types";
import { Button } from "../../ui/button";
import HeaderDropdown from "./header-dropdown";
import type { HeaderReturnVariantProps } from "./header.variants";
import { useHeaderItem } from "./use-header-item";

interface HeaderItemProps {
  styles: HeaderReturnVariantProps;
  theme: "light" | "dark";
  item: LayoutNavItem;
}

function HeaderItem({ theme, styles, item }: HeaderItemProps) {
  const {
    buttonProps,
    dropdownLabel,
    hasDropdown,
    buttonColor,
    isExpanded,
    hasCta,
    data,
  } = useHeaderItem(item);
  const { id, title, dropdown } = data;
  const { appearance } = buttonProps;

  const hoverClassMap = {
    dark: "desktop:group-hover:text-blue-400",
    light: "desktop:group-hover:text-blue-100",
  };

  const hoverClass = appearance === "link" ? hoverClassMap[theme] : "";

  return (
    <>
      <Button
        {...buttonProps}
        appearance={appearance}
        className={styles.link({
          className: `${buttonProps.className} ${hoverClass} !outline-none`,
        })}
        color={buttonColor}
        sectionName={`header_${title}`}
        size="md"
        tabIndex={0}
        weight="medium"
      >
        {title}
      </Button>
      {hasDropdown ? (
        <HeaderDropdown
          dropdownId={id}
          dropdownLabel={dropdownLabel}
          hasCta={hasCta}
          isExpanded={isExpanded}
          items={dropdown}
        />
      ) : null}
    </>
  );
}

export default HeaderItem;
