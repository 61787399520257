import { AnimatePresence, m } from "motion/react";
import { usePathname } from "next/navigation";
import type { LayoutSideMenuGroup, Theme } from "../../../types";
import { useInitialValue } from "../../../hooks/use-initital-value";
import { useSectionStore } from "../../util/layout-grid/section-context";
import SideMenuActivePageMarker from "./side-menu-active-page-marker";
import SideMenuVisibleSectionHighlight from "./side-menu-visible-section-highlight";
import SideMenuLink from "./side-menu-link";
import type { SideMenuReturnVariantProps } from "./side-menu.variants";

interface SideMenuGroupProps {
  styles: SideMenuReturnVariantProps;
  className?: string;
  theme: Theme;
  group: LayoutSideMenuGroup;
}

function SideMenuGroup({ styles, className = "", group }: SideMenuGroupProps) {
  const { title, menu } = group;

  const isInsideMobileNavigation = false;

  const [pathname] = useInitialValue(
    [usePathname(), useSectionStore((s) => s.sections)],
    isInsideMobileNavigation
  );

  const isActiveGroup = menu.findIndex((item) => item.link === pathname) !== -1;

  return (
    <li className={styles.group({ className })}>
      <m.h2 className={styles.title()} layout="position">
        {title}
      </m.h2>
      <div className={styles.content()}>
        <AnimatePresence initial={!isInsideMobileNavigation}>
          {isActiveGroup ? (
            <SideMenuVisibleSectionHighlight
              group={group}
              pathname={pathname}
              styles={styles}
            />
          ) : null}
        </AnimatePresence>
        <m.div className={styles.line()} layout />
        <AnimatePresence initial={false}>
          {isActiveGroup ? (
            <SideMenuActivePageMarker
              group={group}
              pathname={pathname}
              styles={styles}
            />
          ) : null}
        </AnimatePresence>
        <ul className={styles.list()}>
          {menu.map((item) => (
            <m.li className={styles.item()} key={item.link} layout="position">
              <SideMenuLink
                active={item.link === pathname}
                href={item.link}
                label={item.title}
                openInNewTab={item.openInNewTab}
                sectionName="side_menu_docs"
                styles={styles}
              />
            </m.li>
          ))}
        </ul>
      </div>
    </li>
  );
}

export default SideMenuGroup;
