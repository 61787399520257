import { useMemo } from "react";
import { hamburger, type HamburgerVariantProps } from "./hamburger.variants";

export type UseHamburgerProps = HamburgerVariantProps & {
  className?: string;
  ariaControls: string;
  toggle: () => void;
};

export const useHamburger = (props: UseHamburgerProps) => {
  const {
    className = "",
    active = false,
    theme = "dark",
    ariaControls,
    toggle,
  } = props;

  const styles = useMemo(() => hamburger({ theme, active }), [theme, active]);

  const ariaExpanded = active;
  const ariaLabel = active ? "Open menu" : "Close menu";

  return {
    className,
    styles,
    ariaExpanded,
    ariaControls,
    ariaLabel,
    toggle,
  };
};
