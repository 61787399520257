import { tv, type VariantProps } from "../../../util/tv";

export const sideMenuVariant = tv({
  slots: {
    base: [
      "w-full",
      "fixed lg:relative bottom-0 left-0 lg:bottom-auto",
      "px-8 lg:px-0",
      "bg-black/70 lg:bg-transparent ",
      "backdrop-blur lg:backdrop-blur-none",
    ],
    button: ["fixed bottom-8 right-8 mx-auto", "shadow-md", "lg:hidden"],
    chevron: ["w-4 h-4", "duration-300 transform"],
    wrapper: [
      "flex flex-col",
      "h-0 lg:h-auto",
      "mt-3 lg:mt-0",
      "transition-opacity duration-300",
      "lg:opacity-100",
    ],
    menu: ["h-full", "overflow-y-auto lg:overflow-hidden"],
    group: ["relative", "mt-6"],
    title: ["text-display-14 font-semibold"],
    content: ["relative", "mt-4 pl-2"],
    highlight: ["absolute inset-x-0 top-0", "will-change-transform"],
    marker: ["absolute left-2 h-6", "w-px"],
    line: ["absolute inset-y-0 left-2", "w-px"],
    list: ["border-l border-transparent"],
    item: ["relative"],
    link: [
      "flex justify-between gap-2",
      "font-semibold",
      "py-1 pr-3",
      "text-display-14 leading-[1.71]",
      "transition",
    ],
  },
  variants: {
    theme: {
      dark: {
        base: [],
        title: ["text-white"],
        highlight: ["bg-white/20"],
        marker: ["bg-blue-400"],
        line: ["bg-white/20"],
      },
      light: {
        base: [],
        title: ["text-black"],
        highlight: ["bg-black/20"],
        marker: ["bg-blue-100"],
        line: ["bg-black/20"],
      },
    },
    isOpen: {
      false: {
        button: ["z-50"],
        wrapper: ["opacity-0"],
      },
      true: {
        base: ["h-full", "z-[3999]"],
        wrapper: ["h-full", "opacity-100"],
        button: ["z-[4000]"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type SideMenuVariantProps = VariantProps<typeof sideMenuVariant>;

export type SideMenuReturnVariantProps = ReturnType<typeof sideMenuVariant>;
