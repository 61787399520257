import type { LayoutNavItem } from "../../../types";
import type { ButtonProps } from "../../ui/button/button";
import useHeaderState from "./use-header-state";

export type UseHeaderItemProps = LayoutNavItem;

export const useHeaderItem = (props: UseHeaderItemProps) => {
  const { id, title, link, openNewWindow, dropdown = [] } = props;
  const { theme, expanded, opened, onToggleMenu } = useHeaderState();

  const dropdownLabel = `dropdown-${title}`;
  const hasDropdown = dropdown.length > 0;
  const buttonColor: ButtonProps["color"] =
    theme === "dark" ? "white" : "black";

  const isExpanded = id === expanded;

  let buttonProps: ButtonProps = {};

  if (hasDropdown) {
    buttonProps = {
      id: dropdownLabel,
      href: link || undefined,
      title,
      endIcon: "chevron-down",
      className:
        "mobile:pointer-events-none mobile:select-none mobile:text-display-14 mobile:text-gray-40 mobile:[&>svg]:hidden",
      "aria-expanded": isExpanded,
      "aria-haspopup": true,
    };
  } else if (link) {
    buttonProps = {
      href: link,
      title,
      appearance: link.includes("/dash/auth/signup") ? "solid" : "link",
    };
  }

  const hasCta = dropdown.some(({ cta }) => !!cta);

  return {
    buttonProps,
    opened,
    isExpanded,
    onToggleMenu,
    hasDropdown,
    dropdownLabel,
    buttonColor,
    hasCta,
    data: {
      id,
      title,
      link,
      openNewWindow,
      dropdown,
    },
  };
};
