import NextLink from "next/link";
import useUTMParams from "../../../hooks/use-utm-params";
import { slugify } from "../../../util/text";
import type { SideMenuReturnVariantProps } from "./side-menu.variants";

interface SideMenuLinkProps {
  styles: SideMenuReturnVariantProps;
  label: string;
  href: string;
  sectionName: string;
  active?: boolean;
  isAnchorLink?: boolean;
  openInNewTab?: boolean;
}

function SideMenuLink({
  styles,
  sectionName,
  href,
  label,
  active = false,
  isAnchorLink = false,
  openInNewTab = false,
}: SideMenuLinkProps) {
  const { appendUTMParams } = useUTMParams();
  const slugifiedSectionName = slugify(`${sectionName}_${label}`);

  return (
    <NextLink
      aria-current={active ? "page" : undefined}
      className={styles.link({
        className: [
          isAnchorLink ? "pl-7" : "pl-4",
          active ? "text-white" : "text-gray-40 hover:text-white",
        ],
      })}
      href={appendUTMParams(href, slugifiedSectionName)}
      target={openInNewTab ? "_blank" : "_self"}
    >
      <span className="truncate">{label}</span>
    </NextLink>
  );
}

export default SideMenuLink;
