import type { LayoutNavItem } from "../../../types";
import type { HeaderReturnVariantProps } from "./header.variants";
import useHeaderState from "./use-header-state";
import HeaderItem from "./header-item";

interface HeaderNavProps {
  styles: HeaderReturnVariantProps;
  menu: LayoutNavItem[];
  theme: "light" | "dark";
}

function HeaderNav({ theme, styles, menu }: HeaderNavProps) {
  const { openDropdown, cancelCloseDropdown, closeDropdown } = useHeaderState();

  const finalMenuLength = menu.length - 2;
  const siteMenu = menu.slice(0, finalMenuLength);
  const ctaMenu = menu.slice(finalMenuLength, menu.length);

  return (
    <nav className={styles.nav()} id="header-nav">
      <div className={styles.group()}>
        <ul className={styles.list()}>
          {siteMenu.map((item) => (
            <li
              className={styles.item({
                className: "desktop:py-5 desktop:px-2",
              })}
              key={`dropdown-${item.id}`}
              onMouseEnter={() => {
                cancelCloseDropdown();
                openDropdown(item.id);
              }}
              onMouseLeave={() => {
                closeDropdown();
              }}
            >
              <HeaderItem item={item} styles={styles} theme={theme} />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.group()}>
        <ul className={styles.list()}>
          {ctaMenu.map((item) => (
            <li className={styles.item()} key={item.id}>
              <HeaderItem item={item} styles={styles} theme={theme} />
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default HeaderNav;
