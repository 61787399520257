"use client";

import { LazyMotion, domMax, m } from "motion/react";
import { Hamburger } from "../../util/hamburger";
import { useHeader, type UseHeaderProps } from "./use-header";
import HeaderShareBar from "./header-share-bar";
import HeaderBrand from "./header-brand";
import HeaderNav from "./header-nav";

type HeaderProps = UseHeaderProps;

const variants = {
  visible: { y: 0 },
  hidden: { y: -80 },
};

function Header(props: HeaderProps) {
  const { styles, ref, headerState, data } = useHeader(props);

  const { theme, opened, hidden, onToggleMenu, showShareBar } = headerState;
  const { locale, siteName, menu } = data;

  return (
    <LazyMotion features={domMax}>
      <m.header
        animate={hidden ? "hidden" : "visible"}
        className={styles.base()}
        ref={ref}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
        variants={variants}
      >
        <div className={styles.bar()}>
          <HeaderBrand locale={locale} siteName={siteName} styles={styles} />
          <HeaderNav menu={menu} styles={styles} theme={theme} />
          <Hamburger
            active={opened}
            ariaControls="header-nav"
            className={styles.toggle()}
            theme={theme}
            toggle={() => {
              onToggleMenu(!opened);
            }}
          />
        </div>
      </m.header>
      {showShareBar ? <HeaderShareBar /> : null}
    </LazyMotion>
  );
}

export default Header;
