/* eslint-disable no-param-reassign -- disable */
import { strapiUrl } from "./env";

const getStrapiURL = (path = ""): string => `${strapiUrl}${path}`;

const getStrapiMedia = (url: string) => {
  if (url.startsWith("http") || url.startsWith("//")) {
    return url;
  }

  return getStrapiURL(url);
};

const contentTypePatterns = [
  { regex: /^blog\/[^/]+$/, resource: "article-categories" },
  { regex: /^blog\/[^/]+\/[^/]+$/, resource: "articles" },
  { regex: /^modules\/[^/]+$/, resource: "module-categories" },
  { regex: /^modules\/[^/]+\/[^/]+$/, resource: "modules" },
  { regex: /^workflows\/[^/]+$/, resource: "workflow-categories" },
  { regex: /^workflows\/[^/]+\/[^/]+$/, resource: "workflows" },
  { regex: /^docs\/[^/]+\/[^/]+$/, resource: "documentations" },
  { regex: /^case-studies\/[^/]+$/, resource: "cases" },
];

const getContentTypeResourceByPattern = (slug: string) => {
  slug = slug.replace(/^\/+|\/+$/g, "");

  for (const pattern of contentTypePatterns) {
    if (pattern.regex.test(slug)) {
      const segments = slug.split("/");

      return {
        resource: pattern.resource,
        slug: segments[segments.length - 1] as string,
      };
    }
  }

  return {
    resource: "pages",
    slug,
  };
};

export { getStrapiURL, getStrapiMedia, getContentTypeResourceByPattern };
