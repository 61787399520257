import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import type { ComponentProps } from "../../../types";
import { cookieBannerVariant } from "./cookie-banner.variants";

export type UseCookieBannerProps = ComponentProps<{
  description: string;
  policy: {
    label: string;
    href: string;
  };
  confirm: string;
}>;

export const useCookieBanner = (props: UseCookieBannerProps) => {
  const { className = "", data } = props;

  const [show, setShow] = useState(false);
  const [accepted, setAccept] = useLocalStorage("CLOSE_COOKIE_POLICY");

  const styles = cookieBannerVariant();

  useEffect(() => {
    if (!accepted) {
      setShow(true);
    }
  }, [accepted]);

  const onAccept = () => {
    setShow(false);
    setAccept(true);
  };

  return {
    className,
    styles,
    show,
    onAccept,
    data,
  };
};
