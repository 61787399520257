"use client";

import { locales } from "../../../config/locales";
import {
  useLangSwitcher,
  type UseLangSwitcherProps,
} from "./use-lang-switcher";

export type LangSwitcherProps = UseLangSwitcherProps;

function LangSwitcher(props: LangSwitcherProps) {
  const { styles, className, locale, changeLocale, isPending } =
    useLangSwitcher(props);

  return (
    <div className={styles.base({ className })}>
      <label className="sr-only" htmlFor="locales">
        Locale
      </label>
      <select
        className={styles.select()}
        defaultValue={locale}
        disabled={isPending}
        id="locales"
        onChange={changeLocale}
      >
        {Object.entries(locales).map(([key, item]) => {
          return (
            <option key={key} value={item.lang}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default LangSwitcher;
