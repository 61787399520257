import { tv, type VariantProps } from "../../../util/tv";

export const hamburger = tv({
  slots: {
    base: [
      "flex place-items-center shrink-0",
      "w-7 h-7",
      "p-0.5",
      "rounded-md",
      "border-none shadow-none outline-none appearance-none",
      "cursor-pointer",
    ],
    bar: [
      "relative",
      "h-0.5",
      'before:content[""] before:absolute before:h-full before:-top-2',
      'after:content[""] after:absolute after:h-full after:-bottom-2',

      "block w-full rounded-sm",
      "transition duration-300 ease-out",

      "before:block before:w-full before:rounded-sm",
      "before:transition before:duration-300 before:ease-out",

      "after:block after:w-full after:rounded-sm",

      "after:transition after:duration-300 after:ease-out",
    ],
  },
  variants: {
    theme: {
      dark: {
        bar: ["bg-gray-20", "before:bg-gray-20", "after:bg-gray-20"],
      },
      light: {
        bar: ["bg-gray-100", "before:bg-gray-100", "after:bg-gray-100"],
      },
    },
    active: {
      true: {
        bar: [
          "rotate-45",
          "before:rotate-90 before:top-0",
          "after:rotate-90 after:bottom-0",
        ],
      },
    },
  },
});

export type HamburgerVariantProps = VariantProps<typeof hamburger>;
