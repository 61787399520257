"use client";

import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { usePathname } from "next-intl/client";
import { pageView } from "../../../util/google";

function GoogleAnalyticsTracking() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      const search = searchParams.toString();
      pageView(`${pathname}${search ? `?${search}` : ""}`);
    }
  }, [pathname, searchParams]);

  return null;
}

export default GoogleAnalyticsTracking;
