import { useHamburger, type UseHamburgerProps } from "./use-hamburger";

export type HamburgerProps = UseHamburgerProps;

function Hamburger(props: HamburgerProps) {
  const { styles, className, ariaExpanded, ariaLabel, ariaControls, toggle } =
    useHamburger(props);

  return (
    <button
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-label={ariaLabel}
      className={styles.base({ className })}
      onClick={toggle}
      type="button"
    >
      <i aria-hidden="true" className={styles.bar()} />
    </button>
  );
}

export default Hamburger;
