import { useTransition, type ChangeEvent } from "react";
import { useSearchParams } from "next/navigation";
import { usePathname, useRouter } from "next-intl/client";
import type { Locale } from "../../../types";
import { langSwitcher } from "./lang-switcher.variants";

export interface UseLangSwitcherProps {
  className?: string;
  locale: Locale;
}

export const useLangSwitcher = (props: UseLangSwitcherProps) => {
  const { className = "", locale } = props;

  const [isPending, startTransition] = useTransition();

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const styles = langSwitcher();

  const changeLocale = (event: ChangeEvent<HTMLSelectElement>) => {
    const nextLocale = event.target.value;
    const search = searchParams.toString();

    startTransition(() => {
      router.replace(`${pathname}${search ? `?${search}` : ""}`, {
        locale: nextLocale,
      });
    });
  };

  return { className, styles, locale, isPending, changeLocale };
};
