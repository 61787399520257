import { tv, type VariantProps } from "../../../util/tv";

export const layoutGridVariant = tv({
  slots: {
    base: [],
    sidebar: [],
    content: [],
  },
  variants: {
    isDocPage: {
      false: {},
      true: {
        base: ["container-fluid", "grid grid-cols-12 gap-0 lg:gap-12", "pt-28"],
        sidebar: [
          "col-span-12 lg:col-span-3",
          "lg:pr-12",
          "lg:border-r lg:border-white/10",
        ],
        content: ["col-span-12 lg:col-span-9"],
      },
    },
  },
  defaultVariants: {
    isDocPage: false,
  },
});

export type LayoutGridVariantProps = VariantProps<typeof layoutGridVariant>;
